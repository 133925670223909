'use client';

import { Alert, Box, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import CookiesIcon from 'assets/icon24/cookies.svg';
import Link from 'next/link';

const CookieCheck = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const cookiesChecked = localStorage.getItem('cookiesCheck');

    if (!cookiesChecked) {
      setOpen(true);
    }
  }, []);

  if (!open) {
    return null;
  }

  const setCookiesChecked = () => {
    localStorage.setItem('cookiesCheck', 'checked');
    setOpen(false);
  };

  return (
    <Alert
      variant='filled'
      sx={(theme) => ({
        height: 'auto !important',
        zIndex: theme.zIndex.snackbar,
        p: 0,
        bgcolor: 'transparent',
        position: 'fixed',
        bottom: { phxs: 16, tbxs: 20 },
        left: 0,
        maxWidth: 640,
        width: { phxs: '100%', tbxs: 'auto' },
        margin: 'auto',
        px: 2,
        '.MuiAlert-message': {
          color: 'white',
          borderRadius: 4,
          background: 'linear-gradient(90deg, #2B2D36 0%, rgba(43, 45, 54, 0.00) 100%), #202127',
          p: 2,
          width: 360
        }
      })}
      severity='success'
      icon={false}
    >
      <Box display='flex' width='100%' gap={1.5}>
        <Box display={'flex'} flex={'none'} py={0.5}>
          <CookiesIcon />
        </Box>

        <Box
          display={'flex'}
          gap={2}
          width={1}
          flexDirection={{
            phxs: 'column',
            // phlg: 'row',
            tbxs: 'column'
          }}
        >
          <Box display={'flex'} gap={0.5} flexDirection={'column'} flex={'none'}>
            <Typography variant='subtitle2' fontWeight={700}>
              We use cookies
            </Typography>
            <Typography variant='body2' color={'grey.300'}>
              View our Cookie Policy for details
            </Typography>
          </Box>

          <Box
            width={1}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            gap={{ phxs: 1, phlg: 1.5 }}
            flex={'none'}
          >
            <Box display='flex'>
              <Link href={'/support/cookie-policy'} passHref>
                <Button
                  variant='tertiary'
                  color='secondary'
                  size='small'
                  sx={{ width: 1, minWidth: 126, whiteSpace: 'nowrap' }}
                >
                  Cookie Policy
                </Button>
              </Link>
            </Box>

            <Button
              variant='contained'
              color='primary'
              size='small'
              onClick={setCookiesChecked}
              sx={{ width: 1, minWidth: { phxs: 'auto', ph: 126 } }}
            >
              Got it
            </Button>
          </Box>
        </Box>
      </Box>
    </Alert>
  );
};

export default CookieCheck;
