import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const FooterDetails: React.FC = () => {
  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='space-around'
      gap={1}
      flexDirection={'column'}
    >
      {/* <Typography
        variant='body1'
        align='center'
        fontWeight={300}
        color={(theme) => theme.palette.grey[300]}
      >
        DROPSKIN is operated by MALLET SOLUTIONS LIMITED (Registered in Gibraltar) Company No 124605
        Registered Office: Suite 4.3.2, Block 4, Eurotowers, GX11 1AA Gibraltar, Phone:
        +447418334443.
      </Typography>
      <Typography
        variant='body1'
        align='center'
        fontWeight={300}
        color={(theme) => theme.palette.grey[300]}
      >
        Payments may be handled on behalf of MALLET SOLUTIONS LP (United Kingdom) Registration No.
        NL000624, having its registered address at Suite 425 142a Saintfield Road, Lisburn, Northern
        Ireland, BT27 6UH.
      </Typography> */}

      <Typography variant='body1' color={(theme) => theme.palette.grey[300]}>
        The Website is operated by EPICBYTE LTD, Company No 16230898, 128 City Road, London, United
        Kingdom, EC1V 2NX, Phone: +447418320067.
      </Typography>

      <Typography
        variant='body1'
        align='center'
        fontWeight={300}
        color={(theme) => theme.palette.grey[300]}
      >
        © 2024 Drop.Skin All rights reserved
      </Typography>
      {/* <Typography
        variant='body1'
        align='center'
        fontWeight={300}
        color={(theme) => theme.palette.grey[300]}
      >
        The Website is operated by Mallet Solutions LP (Company registered in Northern Ireland)
        <br />
        Suite 425 142a Saintfield Road, Lisburn, Northern Ireland, BT27 6UH Phone: +442070975811.
        <br />
        Powered by Steam. Not affiliated with Valve Corp. You can open CS:GO cases on our website
        and get skins at the cheapest price.
        <br />© 2024 Drop.Skin All rights reserved
      </Typography> */}
    </Box>
  );
};

export default FooterDetails;
