import PaySafe from 'oldAssets/drop/paysafe.svg';
import PayPal from 'oldAssets/drop/paypal.svg';
import GPay from 'oldAssets/drop/gpay.svg';
import Visa from 'oldAssets/drop/visa.svg';

import SkinifyIcon from 'oldAssets/drop/logoSkinify.svg';
import BitcoinIcon from 'oldAssets/payments/BitcoinLogo.svg';

import MasterCardLogo from 'oldAssets/payments/MasterCardLogo.svg';

import ZenLogo from 'oldAssets/payments/zen-logo.svg';

export const FOOTER_LINKS = [
  {
    text: 'Terms of Service',
    link: '/support/terms-and-privacy/Terms & Conditions'
  },
  {
    text: 'Privacy Policy',
    link: '/support/terms-and-privacy/Privacy Policy'
  },
  {
    text: 'Cookie Policy',
    link: '/support/cookie-policy'
  },
  {
    text: 'AML Policy',
    link: '/support/aml-policy'
  },
  {
    text: 'Payment Policy',
    link: '/support/payment-policy'
  },
  {
    text: 'Provably Fair',
    link: '/fair',
    isAuth: true
  },
  {
    text: 'Contact Us',
    link: '/contact-us'
  },
  {
    text: 'Partnership',
    link: '/partnership'
  },
  {
    text: 'FAQ',
    link: '/support'
  }
];

export const FOOTER_IMAGES = [
  {
    text: 'PaySafe',
    icon: <PaySafe />
  },
  {
    text: 'PayPal',
    icon: <PayPal />
  },
  {
    text: 'GPay',
    icon: <GPay />
  },
  {
    text: 'Visa',
    icon: <Visa />
  },
  {
    text: 'Master',
    icon: <MasterCardLogo />
  },
  {
    text: 'BTC',
    icon: <BitcoinIcon />
  },
  {
    text: 'Skinify',
    icon: <SkinifyIcon width={69} />
  },
  {
    text: 'Zen',
    icon: <ZenLogo />
  }
];
